<template>
	<section class="content">
		<table class="table table-hover w-100" ref="tablemembertype">
			<thead>
				<tr>
					<th>TITLE</th>
					<th>DESCRIPTION</th>
					<th>TYPE</th>
					<th>TINDAKAN</th>
				</tr>
			</thead>
			<tbody @click="handleClick"></tbody>
		</table>
		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit="submitForm">
						<div class="modal-header">
							<h5 class="modal-title">{{ formTitle }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<p v-if="errors.length" class="alert alert-danger">
								<b>Please correct the following error(s):</b>
							</p>
							<div class="row">
								<div class="col-md-6 form-group">
									<label class="control-label">Title</label>
									<input type="hidden" name="row_id" value="" />
									<input id="title" class="form-control" v-model="form.title" type="text" required="required" name="title" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Type</label>
									<select id="type" class="form-control" v-model="form.type" type="text" required="required" name="type">
										<option value="boscod">bosCOD</option>
										<option value="bosagen">bosAgen</option>
									</select>
								</div>
								<div class="col-md-12 form-group">
									<label class="control-label">Description</label>
									<input type="hidden" name="row_id" value="" />
									<input id="description" class="form-control" v-model="form.description" type="text" required="required" name="description" />
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary">Save changes</button>
							<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
	name: "JenisMembership",
	data() {
		return {
			errors: [],
			method: "",
			roles: "",
			formTitle: "Tambah Jenis Membership",
			form: {
				title: "",
				description: "",
				type: 0
			}
		};
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		console.log("load initial data", this.$route);
	},
	methods: {
		handleClick(e) {
		if (e.target.closest("button")) {
			let id = e.target.dataset.id;
				if (e.target.dataset.action == "view") {
					this.$router.push("/settings/membertype/detail/" + id);
				}
			}
		},
		submitForm: function (ev) {
			const e = this.$refs;
			var data = Object.keys(this.form)
				.map(
					(key) =>
						encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
				)
				.join("&");
			var urlSubmit = "/master/membertype";
			if (this.method == "PUT")
				urlSubmit = "/master/membertype/" + this.form.id;

			authFetch(urlSubmit, {
				method: this.method,
				body: data,
			})
				.then((res) => {
					if (res.status === 201) {} else if (res.status === 400) {}
					return res.json();
				})
				.then((js) => {
					this.errors = [];
					if (!js.success) {
						console.log(js.details);

						for (var key in js.details) {
							if (js.details.hasOwnProperty(key)) {
								this.errors.push(js.details[key]);
							}
						}

						return;
					}
					this.table.api().ajax.reload();
					$(e.formDialog).modal("hide");
				});

			ev.preventDefault();
		},
	},
	mounted() {
		const e = this.$refs;
		var self = this;
		this.table = createTable(e.tablemembertype, {
			title: "Data Jenis Membership",
			roles: this.$route.params.roles,
			ajax: "/master/membertype",
			filterBy: [0, 1],
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			scrollX: true,
			columns: [
			{ data: "title" }, 
			{ data: "description" },
			{
				data: "type",
				sortable: false,
				render: function (data, type, row, meta) {
					return `<span class="h-fit badge badge-` + (data == 'bosagen' ? 'agen' : 'cod') + `">bos` + (data == 'bosagen' ? 'Agen' : 'COD') + `</span>`
				}
			},
			{
				data: "action",
				sortable: false,
				render: function (data, type, row, meta) {
					return `
						<div class="btn-group">
							<button type="button" class="btn btn-sm btn-primary" data-action="view" data-id="` + row.id + `"><i class="fas fa-eye"></i>\u00A0Detail</button>
						</div>`;
					},
				},
			],
			buttonClick: (evt) => {
				if (evt.role == "create") {
					self.form = {};
					self.method = "POST";
					self.errors = [];
					self.formTitle = "Tambah Jenis Membership";
					$(e.formDialog).modal("show");
				} else if (evt.role == "update" && evt.data) {
					self.form = evt.data;
					self.method = "PUT";
					self.errors = [];
					self.formTitle = "Edit Jenis Membership";
					$(e.formDialog).modal("show");
				} else if (evt.role == "delete" && evt.data) {
					// self.form = evt.data;
					console.log(evt);
					Swal.fire({
						title: "Hapus data?",
						icon: "question",
						denyButtonText: '<i class="fa fa-times"></i> Hapus',
						showCancelButton: true,
						showDenyButton: true,
						showConfirmButton: false,
					}).then((result) => {
						if (result.isDenied) {
							authFetch("/master/membertype/" + evt.data.id, {
								method: "DELETE",
								body: "id=" + evt.data.id,
							})
								.then((res) => {
									return res.json();
								})
								.then((js) => {
									this.table.api().ajax.reload();
								});
						}
					});
				}
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
	},
};
</script>